import Link from "next/link";

const FooterStaticMenu = () => {
  return (
    <ul>
      <li>
        <Link href={"/"} title="হোম">
          হোম
        </Link>
      </li>
      <li>
        <Link href={"/videos"} title="ভিডিও" prefetch={false}>
          ভিডিও
        </Link>
      </li>
      <li>
        <Link href={"/photos"} title="ফটো" prefetch={false}>
          ফটো
        </Link>
      </li>
      <li>
        <Link href={"/programs"} title=" আমাদের প্রোগ্রাম" prefetch={false}>
          আমাদের প্রোগ্রাম
        </Link>
      </li>
      {/* <li>
                                                    <Link href={"/schedule"}>
                                                        আমাদের সময়সূচি
                                                    </Link>
                                                </li> */}
      <li>
        <Link href={"/info/about"} title="আমাদের সম্পর্কে" prefetch={false}>
          আমাদের সম্পর্কে
        </Link>
      </li>
      <li>
        <Link href={"/contact"} title="যোগাযোগ" prefetch={false}>
          যোগাযোগ
        </Link>
      </li>
    </ul>
  );
};

export default FooterStaticMenu;
